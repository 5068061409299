import React, {
  useState, useEffect, useContext, useCallback,
  useMemo,
} from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import useAPI from 'components/hooks/useAPI';
import { useSocket } from 'components/providers/SocketProvider';
import { useChat } from 'components/providers/ChatProvider';

export const FriendListContext = React.createContext({});

function FriendListProvider({ children }) {
  const [isLoading, setIsLoading] = useState(false);

  const [friends, setFriends] = useState([]);
  const [friendRequests, setFriendRequests] = useState([]);

  const [selectedMode, setSelectedMode] = useState(''); // ['list', 'chat']

  const { socket, isConnected } = useSocket();
  const { reauthenticateChat } = useChat();
  const { get } = useAPI();

  const loadFriendList = useCallback(async () => {
    try {
      setIsLoading(true);
      const { friends: _friends, friendRequests: _friendRequests } = await get('/player/friends');

      setFriends(_friends);
      setFriendRequests(_friendRequests);

      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  }, []);

  const denyFriendRequest = useCallback(async (playerId) => {
    try {
      await get(`/player/friends/deny/${playerId}`);
      setFriendRequests((old) => old.filter((entry) => entry.playerId !== playerId));
    } catch (e) {
      console.log(e);
    }
  }, [loadFriendList, get]);

  const acceptFriendRequest = useCallback(async (playerId) => {
    try {
      await get(`/player/friends/accept/${playerId}`);

      await reauthenticateChat();

      const friendData = friendRequests.find((entry) => entry.playerId === playerId);

      if (!friendData) return;

      setFriends((old) => [...old, friendData]);
      setFriendRequests((old) => old.filter((entry) => entry.playerId !== playerId));
    } catch (e) {
      console.log(e);
    }
  }, [loadFriendList, get]);

  useEffect(() => {
    const handleFriendRequestReceivedEvent = async () => {
      try {
        await loadFriendList();
      } catch (e) {
        console.log(e);
      }
    };

    const handleFriendRequestAcceptedEvent = async () => {
      try {
        await loadFriendList();

        await reauthenticateChat();

        // if a user gets a new friend, we need to reauthenticate the chat
      } catch (e) {
        console.log(e);
      }
    };

    const handleFriendDeletedEvent = async () => {
      try {
        await loadFriendList();

        // if a user lost or deleted a friend, we need to reauthenticate the chat
        await reauthenticateChat();
      } catch (e) {
        console.log(e);
      }
    };

    if (!isConnected) return undefined;

    socket.on('friendRequestReceived', handleFriendRequestReceivedEvent);
    socket.on('friendRequestAccepted', handleFriendRequestAcceptedEvent);
    socket.on('friendDeleted', handleFriendDeletedEvent);

    return () => {
      socket.off('friendRequestReceived');
      socket.off('friendRequestAccepted');
      socket.off('friendDeleted');
    };
  }, [isConnected, get, socket]);

  const removeFriend = useCallback((friendId) => {
    setFriends((old) => old.filter((entry) => entry.playerId !== friendId));
  }, []);

  const values = useMemo(() => ({
    friends,
    friendRequests,

    removeFriend,
    denyFriendRequest,
    acceptFriendRequest,
    loadFriendList,

    selectedMode,

    isLoading,
    setIsLoading,
    setSelectedMode,

  }), [

    isLoading,
    selectedMode,

    friends,
    friendRequests,

    removeFriend,
    loadFriendList,
    denyFriendRequest,
    acceptFriendRequest,

    setSelectedMode,
  ]);

  return (
    <FriendListContext.Provider
      value={values}
    >
      {children}
    </FriendListContext.Provider>
  );
}

FriendListProvider.propTypes = {
  children: PropTypes.node,
};

FriendListProvider.defaultProps = {
  children: {},
};

export const useFriendList = () => {
  const data = useContext(FriendListContext);

  return data;
};

export default FriendListProvider;
