import React, {
  useEffect, useMemo, useState, createContext, useContext,
} from 'react';

// Hooks
import { useTournament } from 'components/tournaments/TournamentPage';
import useAPI from 'components/hooks/useAPI';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';

// Components
import TournamentBracketViewer from './TournamentBracketViewer';
import TournamentBracketSocketHandler from './TournamentBracketSocketHandler';

function TournamentBracket() {
  const { isAuthenticated } = useAuth0();
  const {
    publicId,
  } = useTournament();

  const [bracket, setBracket] = useState([]);

  const [disabled, setDisabled] = useState(false);
  const [status, setStatus] = useState('');

  const { post } = useAPI();
  const { t } = useTranslation(['general']);

  const loadBracket = async () => {
    try {
      setDisabled(true);
      setStatus('');

      let _bracket = [];

      if (!isAuthenticated) {
        _bracket = await post(`/tournaments/bracket/public/${publicId}`);
      } else {
        _bracket = await post(`/tournaments/bracket/${publicId}`);
      }

      setBracket(_bracket);
      setDisabled(false);
    } catch (e) {
      console.log(e);
      setStatus(t('general:data_loading_error'));
      setDisabled(false);
    }
  };

  useEffect(() => {
    loadBracket();
  }, []);

  const renderedBracket = useMemo(() => {
    if (bracket.length === 0) return null;

    //  const modifiedBracket = bracket.slice(1);

    return <TournamentBracketViewer bracket={bracket} />;
  }, [bracket, disabled]);

  return (
    <div className="">
      <h2 className="is-size-3 has-text-standard has-text-weight-light">
        <i className="fas fa-play mr-4 has-text-primary" />
        {t('general:bracket')}
      </h2>

      <TournamentBracketSocketHandler
        bracket={bracket}
        setBracket={setBracket}
      />

      <div className="">
        { renderedBracket }
      </div>

      {status && (
        <div className="has-text-weight-bold mt-5">
          {status}
        </div>
      )}
    </div>
  );
}

export default TournamentBracket;
