import React, { useMemo, useState, useEffect } from 'react';

// Hooks
import { useTournament } from 'components/tournaments/TournamentPage';
import { useTranslation } from 'react-i18next';
import useAPI from 'components/hooks/useAPI';
import { useAuth0 } from '@auth0/auth0-react';

// Assets
import trophy from 'assets/images/icons/engagement_competitive.svg';
import CardPicDisplay from 'components/utils/CardPicDisplay';
import TournamentBracket from '../../common/TournamentBracket';

function TournamentResults() {
  const { publicId } = useTournament();
  const { isAuthenticated } = useAuth0();

  const { t } = useTranslation(['general', 'tournaments']);
  const [results, setResults] = useState([]);

  const { get } = useAPI();

  useEffect(() => {
    const loadResults = async () => {
      try {
        let _results;

        if (isAuthenticated) {
          _results = await get(`/tournaments/results/${publicId}`);
        } else {
          _results = await get(`/tournaments/results/public/${publicId}`);
        }

        setResults(_results);
      } catch (e) {
        console.log(e);
      }
    };

    loadResults();
  }, [publicId, isAuthenticated]);

  const renderedWinner = useMemo(() => {
    if (!results || results.length === 0) {
      return null;
    }

    const winner = results[0];

    return (
      <div className="has-text-centered my-5">

        <p className="my-4 has-text-weight-bold is-size-4">
          {t('general:winner')}
        </p>

        <div className="is-flex has-content-centered">
          <img
            src={trophy}
            alt="trophy"
            className="has-max-width-250"
          />
          <div className="has-max-width-100">
            <CardPicDisplay
              img={winner.cardPic}
              url={winner.participantType === 'team' ? '/images/teams/card_pictures/' : undefined}
            />
          </div>
          <p className="has-text-weight-bold mx-3 is-size-4">
            {winner.name}
          </p>
        </div>

      </div>
    );
  }, [results]);

  const renderedResults = useMemo(() => {
    if (!results || results.length === 0) {
      return null;
    }

    let rank = 0;

    const renderedStandings = results.map((participantData, index) => {
      const { name, results: participantResults } = participantData;

      // If the previous participant has the same score, we don't increment the rank
      // except if it's the second participant (because that is always rank 2)
      if (index > 2) {
        const previousParticipantResults = results[index - 1].results;

        if (
          participantResults.wins === previousParticipantResults.wins
          && participantResults.losses === previousParticipantResults.losses
        ) {
          rank -= 1;
        }
      }

      rank += 1;

      return (
        <tr key={index}>
          <th>{rank}</th>
          <th>{name}</th>
          <th>{participantResults.wins}</th>
          <th>{participantResults.losses}</th>
        </tr>
      );
    });

    return (
      <div className="px-6">
        <p className="is-size-4 mt-6 has-text-weight-bold has-text-centered">
          {t('general:results')}
        </p>

        <table className="table mt-3 has-margin-auto is-size-5">
          <thead>
            <tr>
              <th>{t('general:rank')}</th>
              <th>{t('general:name')}</th>
              <th>{t('general:wins')}</th>
              <th>{t('general:losses')}</th>
            </tr>
          </thead>
          <tbody>
            {renderedStandings}
          </tbody>
        </table>
      </div>
    );
  }, [results, t]);

  return (
    <div className="px-6 pb-6">
      <p className="is-size-3 has-text-weight-bold has-text-centered">
        {`${t('general:tournament_is_over')}!`}
      </p>

      {renderedWinner}
      {renderedResults}

      <div className="mt-6">
        <TournamentBracket />
      </div>
    </div>
  );
}

export default TournamentResults;
