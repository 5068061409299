import React, {
  useCallback,
  useEffect,
} from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useSocket } from 'components/providers/SocketProvider';
import { useTournament } from 'components/tournaments/TournamentPage';
import useAPI from 'components/hooks/useAPI';

export const TournamentSocketContext = React.createContext({});

function TournamentBracketSocketHandler({ bracket, setBracket }) {
  const {
    publicId,
  } = useTournament();

  const { socket, isConnected } = useSocket();
  const { post } = useAPI();

  const loadMatchesUpdates = async (publicIds) => {
    try {
      const newMatches = await post(`/tournaments/bracket/matches/${publicId}`, publicIds);

      setBracket((prevBracket) => {
        const updatedBracket = prevBracket.map((round) => {
          const { matches } = round;

          const updatedMatches = matches.map((match) => {
            const updatedMatch = newMatches.find((_match) => _match.publicId === match.publicId);

            if (!updatedMatch) return match;

            return updatedMatch;
          });

          return {
            ...round,
            matches: updatedMatches,
          };
        });

        return updatedBracket;
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (!socket) return undefined;

    if (!isConnected) {
      socket.off('tournamentBracketUpdated', loadMatchesUpdates);
      return undefined;
    }

    // socket.emit('joinRoom', `tournament_bracket_${publicId}`);
    socket.on('tournamentBracketUpdated', loadMatchesUpdates);

    return () => {
      // socket.emit('leaveRoom', `tournament_bracket_${publicId}`);
      socket.off('tournamentBracketUpdated', loadMatchesUpdates);
    };
  }, [publicId, socket, isConnected]);

  return null;
}

TournamentBracketSocketHandler.propTypes = {
  bracket: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setBracket: PropTypes.func.isRequired,
};

export default TournamentBracketSocketHandler;
