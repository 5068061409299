import React, { useState, useMemo } from 'react';

// Hooks
import { useTournament } from 'components/tournaments/TournamentPage';
import { useTranslation } from 'react-i18next';
import useAPI from 'components/hooks/useAPI';

// Constants
import { TOURNAMENT_PHASES } from 'constants/tournaments';

// Components
import Dropdown from 'components/utils/Dropdown';

function TournamentPhaseChanger() {
  const {
    currentPhase, publicId, setCurrentPhase, setContentToShow,
  } = useTournament();

  const [disabled, setDisabled] = useState(false);
  const [status, setStatus] = useState('');

  const { post } = useAPI();
  const { t } = useTranslation(['general', 'errors']);

  const availablePhases = useMemo(() => {
    const phaseWithoutDraft = TOURNAMENT_PHASES.filter((phase) => phase !== 'draft');

    return phaseWithoutDraft.map((phase) => ({
      label: t(`general:${phase}`),
      value: phase,
    }));
  }, []);

  const selectedPhase = useMemo(() => availablePhases.find((phase) => phase.value === currentPhase), [availablePhases, currentPhase]);

  const changePhase = async (newPhase) => {
    try {
      setDisabled(true);
      setStatus('');

      const data = {
        phase: newPhase.value,
      };

      await post(`/tournaments/phase/${publicId}`, data);

      setDisabled(false);
      setCurrentPhase(newPhase.value);
    } catch (e) {
      console.log(e);
      setDisabled(false);
      setStatus(t('errors:generic_error'));
    }
  };

  return (
    <div className="field">
      <label className="label">
        {t('general:phase')}
      </label>
      <div className="control">
        <Dropdown
          items={availablePhases}
          onSelect={changePhase}
          value={selectedPhase}
          disabled={disabled}
        />

        {status && (
        <p className="mt-2 has-text-weight-bold">
          {status}
        </p>
        )}

      </div>
    </div>
  );
}

export default TournamentPhaseChanger;
