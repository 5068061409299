import React, {
  useEffect, useContext, useMemo,
} from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useSocket } from 'components/providers/SocketProvider';
import { useTournament } from 'components/tournaments/TournamentPage';
import useAPI from 'components/hooks/useAPI';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';

export const TournamentSocketContext = React.createContext({});

function TournamentSocketProvider({ children }) {
  const { isAuthenticated } = useAuth0();
  const {
    publicId,
    participant,

    setCurrentPhase, reevaluatePermissions, setParticipant, setParticipants, removeParticipant, setCheckInCount,
  } = useTournament();

  const { playerId } = useSelector((state) => state.player.player);

  const { socket, isConnected } = useSocket();
  const { get } = useAPI();

  useEffect(() => {
    if (!socket) return undefined;

    const handleTournamentPhaseChange = async (phase) => {
      try {
        if (isAuthenticated) {
          const roles = await get(`/tournaments/roles/${publicId}`);
          reevaluatePermissions(roles);
        }

        setCurrentPhase(phase);
      } catch (e) {
        console.log(e);
      }
    };

    const addParticipants = async (data) => {
      const _participants = data.participants;

      const participantPlayerIsIn = _participants.find((_participant) => _participant.players.find((player) => player.playerId === playerId));

      if (isAuthenticated && participantPlayerIsIn) {
        const roles = await get(`/tournaments/roles/${publicId}`);
        reevaluatePermissions(roles);

        setParticipant((prevParticipant) => ({
          ...prevParticipant,
          ...participantPlayerIsIn,
        }));

        return;
      }

      setParticipants((prevParticipants) => {
        const newParticipants = [...prevParticipants, ..._participants];
        return newParticipants;
      });
    };

    const removeParticipants = (data) => {
      const participantIds = data.participants;

      for (let i = 0; i < participantIds.length; i++) {
        const participantId = participantIds[i];
        // will also remove the player if they are in the participant
        removeParticipant(participantId);
      }
    };

    const handleCheckInCount = (data) => {
      const {
        amountCheckedIn, amountTotal, checkedIn, checkedOut,
      } = data;

      setCheckInCount({
        amountCheckedIn,
        amountTotal,
      });

      if (checkedIn.includes(participant.publicId)) {
        setParticipant((prevState) => ({
          ...prevState,
          isCheckedIn: true,
        }));
      }

      if (checkedOut.includes(participant.publicId)) {
        setParticipant((prevState) => ({
          ...prevState,
          isCheckedIn: false,
        }));
      }

      setParticipants((prevParticipants) => {
        const newParticipants = prevParticipants.map((_participant) => {
          if (checkedIn.includes(_participant.publicId)) {
            return {
              ..._participant,
              isCheckedIn: true,
            };
          }

          if (checkedOut.includes(_participant.publicId)) {
            return {
              ..._participant,
              isCheckedIn: false,
            };
          }

          return _participant;
        });

        return newParticipants;
      });
    };

    const updateParticipants = (data) => {
      const _participants = data.participants;

      setParticipants((prevParticipants) => {
        const newParticipants = prevParticipants.map((_participant) => {
          const updatedParticipant = _participants.find((p) => p.publicId === _participant.publicId);

          if (!updatedParticipant) return _participant;

          return {
            ..._participant,
            ...updatedParticipant,
          };
        });

        return newParticipants;
      });

      setParticipant((prevParticipant) => {
        const updatedParticipant = _participants.find((p) => p.publicId === prevParticipant.publicId);

        if (!updatedParticipant) return prevParticipant;

        return {
          ...prevParticipant,
          ...updatedParticipant,
        };
      });
    };

    if (!isConnected) {
      socket.off('tournamentPhaseChange', handleTournamentPhaseChange);
      socket.off('tournamentParticipantsJoined', addParticipants);
      socket.off('tournamentParticipantsRemoved', removeParticipants);
      socket.off('tournamentParticipantsLeft', removeParticipants);
      socket.off('tournamentParticipantsUpdated', updateParticipants);
      socket.off('tournamentCheckInCount', handleCheckInCount);
      return undefined;
    }

    socket.emit('joinRoom', `tournament_${publicId}`);

    socket.on('tournamentPhaseChange', handleTournamentPhaseChange);
    socket.on('tournamentParticipantsJoined', addParticipants);
    socket.on('tournamentParticipantsRemoved', removeParticipants);
    socket.on('tournamentParticipantsLeft', removeParticipants);
    socket.on('tournamentParticipantsUpdated', updateParticipants);
    socket.on('tournamentCheckInCount', handleCheckInCount);

    return () => {
      socket.emit('leaveRoom', `tournament_${publicId}`);

      socket.off('tournamentPhaseChange', handleTournamentPhaseChange);
      socket.off('tournamentParticipantsJoined', addParticipants);
      socket.off('tournamentParticipantsRemoved', removeParticipants);
      socket.off('tournamentParticipantsLeft', removeParticipants);
      socket.off('tournamentParticipantsUpdated', updateParticipants);
      socket.off('tournamentCheckInCount', handleCheckInCount);
    };
  }, [publicId, socket, isConnected, isAuthenticated, playerId]);

  const values = useMemo(() => ({
    socket,
  }), [
    socket,
  ]);

  return (
    <TournamentSocketContext.Provider
      value={values}
    >
      {children}
    </TournamentSocketContext.Provider>
  );
}

TournamentSocketProvider.propTypes = {
  children: PropTypes.node,
};

TournamentSocketProvider.defaultProps = {
  children: {},
};

export const useTournamentSocket = () => {
  const data = useContext(TournamentSocketContext);

  return data;
};

export default TournamentSocketProvider;
