import React, { useState, useEffect } from 'react';

// Libraries
// import copy from 'copy-to-clipboard';
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';

function CopyButtonSimple({ text, children, className = '' }) {
  const { t } = useTranslation(['general']);

  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (!copied) return undefined;

    const timeout = setTimeout(() => {
      setCopied(false);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [copied]);

  const copyInfo = async () => {
    // copy(text);
    try {
      await navigator.clipboard.writeText(text);
      setCopied(true);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="is-relative is-flex has-content-centered">
      { copied && (
        <div
          className="is-absolute has-max-z-index box boxshadow has-text-weight-semibold is-size-7 px-2 py-1 br7 has-text-white has-border-black-light has-text-centered"
          style={{ top: '-35px' }}
        >
          <p>
            {`${t('general:copied')}!`}
          </p>
        </div>
      )}
      <button
        type="button"
        onClick={copyInfo}
        className={className}
      >
        { children }
      </button>
    </div>

  );
}

CopyButtonSimple.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

CopyButtonSimple.defaultProps = {
  className: '',
};

export default CopyButtonSimple;
