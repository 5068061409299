import React, { useState, useEffect, useMemo } from 'react';

// Libraries
import {
  restrictToFirstScrollableAncestor,
} from '@dnd-kit/modifiers';

// Hooks
import useAPI from 'components/hooks/useAPI';
import { useTournament } from 'components/tournaments/TournamentPage';
import { useTranslation } from 'react-i18next';

// Components
import { DndContext, DragOverlay } from '@dnd-kit/core';
import SeedingMatchDisplay from './SeedingMatchDisplay';
import ParticipantDisplay from './utils/ParticipantDisplay';
import TournamentSeedingSocketHandler from './TournamentSeedingSocketHandler';

const INITIAL_PARTICIPANT = {
  name: '',
  cardPic: '',
};

function TournamentSeedingEditor() {
  const { publicId } = useTournament();

  const [draggedParticipant, setDraggedParticipant] = useState(INITIAL_PARTICIPANT);

  const [bracket, setBracket] = useState([]);

  const [disabled, setDisabled] = useState(false);
  const [status, setStatus] = useState('');

  const { post } = useAPI();
  const { t } = useTranslation(['general']);

  const loadBracket = async () => {
    try {
      setDisabled(true);
      setStatus('');

      let _bracket = await post(`/tournaments/bracket/${publicId}`);

      if (_bracket.length === 0) {
        setBracket(_bracket);
        setDisabled(false);
        return;
      }

      if (_bracket[0].stage === 'preliminary') {
      // get first two rounds, but make sure its a new array
        _bracket = _bracket.slice(0, 2);
      } else {
        _bracket = _bracket.slice(0, 1);
      }

      setBracket(_bracket);
      setDisabled(false);
    } catch (e) {
      console.log(e);
      setStatus(t('general:data_loading_error'));
      setDisabled(false);
    }
  };

  const startTournament = async () => {
    try {
      setDisabled(true);

      await post(`/tournaments/phase/${publicId}`, { phase: 'in_progress' });

      setDisabled(false);
    } catch (e) {
      console.log(e);
      setDisabled(false);
    }
  };

  const saveSwap = async (participants) => {
    try {
      setDisabled(true);

      await post(`/tournaments/bracket/swap/${publicId}`, { participants });

      setDisabled(false);
    } catch (e) {
      console.log(e);
      setDisabled(false);
    }
  };

  useEffect(() => {
    loadBracket();
  }, []);

  const swapParticipants = (participants) => {
    const [participant1, participant2] = participants;

    const newBracket = [...bracket];
    let match1; let
      match2;

    // Find the matches containing the participants
    for (let i = 0; i < newBracket.length; i++) {
      const round = newBracket[i];
      for (let j = 0; j < round.matches.length; j++) {
        const match = round.matches[j];
        if (match.participants.some((participant) => participant.publicId === participant1)) {
          match1 = match;
        }
        if (match.participants.some((participant) => participant.publicId === participant2)) {
          match2 = match;
        }
      }
    }

    // Swap the participants in the matches
    if (match1 && match2) {
      const participantIndex1 = match1.participants.findIndex((participant) => participant.publicId === participant1);
      const participantIndex2 = match2.participants.findIndex((participant) => participant.publicId === participant2);

      if (participantIndex1 !== -1 && participantIndex2 !== -1) {
        const temp = match1.participants[participantIndex1];
        match1.participants[participantIndex1] = match2.participants[participantIndex2];
        match2.participants[participantIndex2] = temp;
      }
    }

    setBracket(newBracket);
  };

  const onDragEnd = async (event) => {
    const { over, active } = event;
    if (!over || !active) {
      setDraggedParticipant(INITIAL_PARTICIPANT);
      return;
    }

    /* const [roundIndex, matchIndex, participantIndex] = over.id.split('_');
    const [draggedRoundIndex, draggedMatchIndex, draggedParticipantIndex] = active.id.split('_'); */

    /* const newBracket = [...bracket]; */

    const toReplace = over.data.current.participant;
    const dragged = active.data.current.participant;

    /* newBracket[roundIndex].matches[matchIndex].participants[participantIndex] = dragged;
    newBracket[draggedRoundIndex].matches[draggedMatchIndex].participants[draggedParticipantIndex] = toReplace;

    setBracket(newBracket); */

    const draggedParticipantId = dragged.publicId;
    const toReplaceParticipantId = toReplace.publicId;

    if (draggedParticipantId === toReplaceParticipantId) return;

    swapParticipants([draggedParticipantId, toReplaceParticipantId]);

    await saveSwap([draggedParticipantId, toReplaceParticipantId]);
  };
  const onDragStart = (e) => {
    if (!e.active || !e.active.data || !e.active.data.current) return;

    const { participant } = e.active.data.current;

    setDraggedParticipant(participant);
  };

  const renderedBracket = useMemo(() => bracket.map((round, roundIndex) => {
    const { stage, matches } = round;

    if (!matches || matches.length === 0) return null;

    const { bestOf } = matches[0];

    const renderedMatches = matches.map((match, matchIndex) => {
      let childMatchData = null;

      if (match.childMatch && match.round + 1 < bracket.length) {
        const nextRoundMatches = bracket[match.round + 1].matches;
        childMatchData = nextRoundMatches.find((childMatch) => childMatch.publicId === match.childMatch);
      }

      return (
        <SeedingMatchDisplay
          match={match}
          childMatch={childMatchData}
          key={match.publicId}
          roundId={roundIndex}
        />
      );
    });

    const roundNumber = stage === 'round' ? ` ${matches[0].round}` : '';

    return (
      <div
        className="column is-relative"
        style={{ padding: '0 40px' }}
        key={`${roundIndex}`}
      >
        <div className="is-relative">
          <div className="has-text-centered">
            <p className="has-text-weight-bold">
              {t(`general:${stage}`) + roundNumber}
            </p>
            <p>
              {`${t('general:best_of')} ${bestOf}`}
            </p>
          </div>

          <div className="is-relative">
            { renderedMatches }
          </div>
        </div>
      </div>
    );
  }), [bracket]);

  const HEIGHT = 145;

  const calculateVerticalStartingPoint = (round, matchNumber, childMatchData) => {
    let roundIndex = round - 1;

    if (roundIndex < 0) roundIndex = 0;

    const heightAdjustment = round >= 1 ? 2 : 1;

    if (round === 0) {
      if (!childMatchData) return 0;

      const { match: childMatchNumber, participants: childParticipants } = childMatchData;

      const childMatchIndex = childMatchNumber - 1;

      let preliminaryTop = (childMatchIndex * 2) * HEIGHT;

      const matchIndex = matchNumber - 1;

      if (matchIndex % 2 === 0 && !childParticipants[0]) {
        preliminaryTop += HEIGHT;
      }

      if (childParticipants[0]) {
        preliminaryTop += HEIGHT;
      }

      return preliminaryTop;
    }

    return (2 ** (roundIndex) * HEIGHT * heightAdjustment) / 2 - HEIGHT / 2;
  };

  const calculateHeightIncrease = (round, match) => {
    let roundIndex = round - 1;
    const matchIndex = match - 1;

    if (roundIndex < 0) roundIndex = 0;

    if (round === 0) {
      return 0;
    }

    const heightAdjustment = round >= 1 ? 2 : 1;

    return (2 ** (roundIndex) * HEIGHT * heightAdjustment) * matchIndex;
  };

  const completeWidth = useMemo(() => {
    const X_OFFSET = 40;
    const SPACE_BETWEEN_MATCHES = 80;
    const MATCH_WIDTH = 250;

    return (MATCH_WIDTH + SPACE_BETWEEN_MATCHES) * bracket.length - X_OFFSET;
  }, [bracket]);

  const completeHeight = useMemo(() => {
    const SVG_OFFSET = 145;

    if (bracket.length === 0) return 0;

    const isPreliminary = bracket[0] && bracket[0].stage === 'preliminary';

    if (isPreliminary) {
      return bracket[1].matches.length * HEIGHT * 2 + SVG_OFFSET;
    }

    return bracket[0].matches.length * HEIGHT * 2;
  }, [bracket]);

  const renderedLines = useMemo(() => {
    if (bracket.length <= 1) return null;

    const firstRound = bracket[0];

    return firstRound.matches.map((matchData) => {
      const { childMatch: childMatchId, round, match } = matchData;

      if (!childMatchId) return null;

      let childMatchData = null;

      const nextRoundIndex = 1;

      if (nextRoundIndex >= bracket.length) return null;

      const nextRoundMatches = bracket[nextRoundIndex].matches;
      const childMatchDataIndex = nextRoundMatches.findIndex((data) => data.publicId === childMatchId);

      if (childMatchDataIndex === -1) return null;

      childMatchData = nextRoundMatches[childMatchDataIndex];

      const X_OFFSET = 40;
      const SPACE_BETWEEN_MATCHES = 74;
      const MATCH_WIDTH = 250;
      const SVG_OFFSET = 145;

      const childMatchPosition = {
        x: (MATCH_WIDTH + SPACE_BETWEEN_MATCHES) - X_OFFSET,
        y: calculateHeightIncrease(childMatchData.round, childMatchData.match) + calculateVerticalStartingPoint(childMatchData.round, childMatchData.match) + SVG_OFFSET,
      };

      const thisMatchPosition = {
        x: (MATCH_WIDTH + SPACE_BETWEEN_MATCHES) - X_OFFSET,
        y: calculateHeightIncrease(round, match) + calculateVerticalStartingPoint(round, match, childMatchData) + SVG_OFFSET,
      };

      return (
        <g key={`${round}_${match}`}>
          <line
            x1={thisMatchPosition.x}
            y1={thisMatchPosition.y}
            x2={thisMatchPosition.x + 40}
            y2={thisMatchPosition.y}
            style={{
              stroke: '#fff',
              strokeWidth: 1,
            }}
          />
          <line
            x1={thisMatchPosition.x + 40}
            y1={thisMatchPosition.y}
            x2={thisMatchPosition.x + 40}
            y2={childMatchPosition.y}
            style={{
              stroke: '#fff',
              strokeWidth: 1,
            }}
          />
          <line
            x1={thisMatchPosition.x + 40}
            y1={childMatchPosition.y}
            x2={thisMatchPosition.x + 80}
            y2={childMatchPosition.y}
            style={{
              stroke: '#fff',
              strokeWidth: 1,
            }}
          />

        </g>
      );
    });
  }, [bracket]);

  return (
    <div className="px-5 has-overflow-y-auto">
      <TournamentSeedingSocketHandler swapParticipants={swapParticipants} />
      <div
        className=""
      >
        { renderedBracket.length !== 0 && (
          <div className="mb-6">
            <p className="has-text-centered has-text-weight-bold">
              {`${t('general:automatic_seeding_done')}!`}
            </p>
            <p className="has-text-centered">
              {t('general:drag_and_drop_to_reorder')}
            </p>
          </div>
        )}

        <div
          className="is-relative has-margin-auto"
          style={{
            width: completeWidth,
            height: completeHeight,
          }}
        >
          <DndContext
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            autoScroll={false}
            modifiers={[restrictToFirstScrollableAncestor]}
          >
            <div
              className="columns is-relative is-centered"
              style={{
                zIndex: 2,
              }}
            >
              {renderedBracket}
            </div>

            <DragOverlay
              dropAnimation={!draggedParticipant.name ? {
                duration: 250,
                easing: 'ease',
              } : null}
            >
              {draggedParticipant.name ? (
                <ParticipantDisplay
                  participant={draggedParticipant}
                  showBorder={false}
                  isActive
                />
              ) : null}
            </DragOverlay>

          </DndContext>

          <svg
            className="is-absolute"
            style={{
              top: 0, left: 0, zIndex: 1, width: completeWidth, height: completeHeight,
            }}
          >
            {renderedLines}
          </svg>

        </div>
      </div>

      <div className="has-text-centered mt-6">
        <button
          type="button"
          className="button is-primary"
          onClick={startTournament}
        >
          {t('general:start_tournament')}
        </button>
      </div>

      <div className="mt-6 has-text-centered">
        <p className="has-text-centered has-text-grey mt-2">
          {status}
        </p>
      </div>
    </div>
  );
}

/*
          <svg
            className="is-absolute"
            style={{
              top: 0, left: 0, zIndex: 1, width: completeWidth, height: completeHeight,
            }}
          >
            {renderedLines}
          </svg>

*/

export default TournamentSeedingEditor;
