import React, { useEffect, useMemo, useRef } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';

// Components
import MatchDisplay from './MatchDisplay';
import PanZoomWrapper from 'components/utils/PanZoomWrapper';

function TournamentBracketViewer(props) {
  const { bracket } = props;

  const { t } = useTranslation(['general']);

  const renderedBracket = useMemo(() => bracket.map((round, roundIndex) => {
    const { stage, matches } = round;

    if (!matches || matches.length === 0) return null;

    const { bestOf } = matches[0];

    const renderedMatches = matches.map((match, matchIndex) => {
      let childMatchData = null;

      if (match.childMatch && match.round + 1 < bracket.length) {
        const nextRoundMatches = bracket[match.round + 1].matches;
        childMatchData = nextRoundMatches.find((childMatch) => childMatch.publicId === match.childMatch);
      }

      // count the match number - all matches in all rounds before this one
      const matchNumber = bracket.slice(0, roundIndex).reduce((acc, curr) => acc + curr.matches.length, 0) + matchIndex + 1;

      return (
        <MatchDisplay
          match={match}
          childMatch={childMatchData}
          key={match.publicId}
          matchNumber={matchNumber}
        />
      );
    });

    const roundNumber = stage === 'round' ? ` ${matches[0].round}` : '';

    return (
      <div
        className="column is-relative"
        style={{ padding: '0 40px' }}
        key={`${roundIndex}`}
      >
        <div className="is-relative">
          <div className="has-text-centered">
            <p className="has-text-weight-bold">
              {t(`general:${stage}`) + roundNumber}
            </p>
            <p>
              {`${t('general:best_of')} ${bestOf}`}
            </p>
          </div>

          <div className="is-relative">
            { renderedMatches }
          </div>
        </div>
      </div>
    );
  }), [t, bracket]);

  const HEIGHT = 167;

  const calculateVerticalStartingPoint = (round, matchNumber, childMatchData) => {
    let roundIndex = round - 1;

    if (roundIndex < 0) roundIndex = 0;

    const heightAdjustment = round >= 1 ? 2 : 1;

    if (round === 0) {
      if (!childMatchData) return 0;

      const { match: childMatchNumber, participants: childParticipants } = childMatchData;

      const childMatchIndex = childMatchNumber - 1;

      let preliminaryTop = (childMatchIndex * 2) * HEIGHT;

      const matchIndex = matchNumber - 1;

      if (matchIndex % 2 === 0) {
        preliminaryTop += HEIGHT;
      }

      if (childParticipants[0]) {
        // preliminaryTop += HEIGHT;
      }

      return preliminaryTop;
    }

    return (2 ** (roundIndex) * HEIGHT * heightAdjustment) / 2 - HEIGHT / 2;
  };

  const calculateHeightIncrease = (round, match) => {
    let roundIndex = round - 1;
    const matchIndex = match - 1;

    if (roundIndex < 0) roundIndex = 0;

    if (round === 0) {
      return 0;
    }

    const heightAdjustment = round >= 1 ? 2 : 1;

    return (2 ** (roundIndex) * HEIGHT * heightAdjustment) * matchIndex;
  };

  const completeWidth = useMemo(() => {
    const X_OFFSET = 40;
    const SPACE_BETWEEN_MATCHES = 80;
    const MATCH_WIDTH = 250;

    return (MATCH_WIDTH + SPACE_BETWEEN_MATCHES) * bracket.length - X_OFFSET;
  }, [bracket]);

  const completeHeight = useMemo(() => {
    const SVG_OFFSET = 145;

    const isPreliminary = bracket[0] && bracket[0].stage === 'preliminary';

    if (isPreliminary) {
      return bracket[1].matches.length * HEIGHT * 2 + SVG_OFFSET;
    }

    console.log('bracket', bracket);

    // if there is a match for third place, we need to increase the height a bit so there is no scrollbar
    if (bracket[bracket.length - 1].matches.length > 1) {
      return bracket[0].matches.length * HEIGHT * 2 + SVG_OFFSET;
    }

    return bracket[0].matches.length * HEIGHT * 2;
  }, [bracket]);

  const renderedLines = useMemo(() => bracket.map((roundData, roundIndex) => roundData.matches.map((matchData, matchIndex) => {
    const { childMatch: childMatchId, round, match } = matchData;

    if (!childMatchId) return null;

    let childMatchData = null;

    const nextRoundIndex = roundIndex + 1;

    if (nextRoundIndex >= bracket.length) return null;

    const nextRoundMatches = bracket[nextRoundIndex].matches;
    const childMatchDataIndex = nextRoundMatches.findIndex((data) => data.publicId === childMatchId);

    if (childMatchDataIndex === -1) return null;

    childMatchData = nextRoundMatches[childMatchDataIndex];

    const X_OFFSET = 40;
    const SPACE_BETWEEN_MATCHES = 74;
    const MATCH_WIDTH = 250;
    const SVG_OFFSET = 168;

    const childMatchPosition = {
      x: (MATCH_WIDTH + SPACE_BETWEEN_MATCHES) * Math.max(1, roundIndex + 1) - X_OFFSET,
      y: calculateHeightIncrease(childMatchData.round, childMatchData.match) + calculateVerticalStartingPoint(childMatchData.round, childMatchData.match) + SVG_OFFSET,
    };

    const thisMatchPosition = {
      x: (MATCH_WIDTH + SPACE_BETWEEN_MATCHES) * Math.max(roundIndex + 1) - X_OFFSET,
      y: calculateHeightIncrease(round, match) + calculateVerticalStartingPoint(round, match, childMatchData) + SVG_OFFSET,
    };

    return (
      <g key={`${round}_${match}`}>
        <line
          x1={thisMatchPosition.x}
          y1={thisMatchPosition.y}
          x2={thisMatchPosition.x + 40}
          y2={thisMatchPosition.y}
          style={{
            stroke: '#fff',
            strokeWidth: 1,
          }}
        />
        <line
          x1={thisMatchPosition.x + 40}
          y1={thisMatchPosition.y}
          x2={thisMatchPosition.x + 40}
          y2={childMatchPosition.y}
          style={{
            stroke: '#fff',
            strokeWidth: 1,
          }}
        />
        <line
          x1={thisMatchPosition.x + 40}
          y1={childMatchPosition.y}
          x2={thisMatchPosition.x + 80}
          y2={childMatchPosition.y}
          style={{
            stroke: '#fff',
            strokeWidth: 1,
          }}
        />

      </g>
    );
  })), [bracket]);

  return (
    <PanZoomWrapper>
      <div
        className=""
      >
        <div
          className="is-relative"
          style={{
            width: completeWidth,
            height: completeHeight,
          }}
        >
          <div
            className="columns is-relative"
            style={{ zIndex: 99 }}
          >
            {renderedBracket}
          </div>

          <svg
            className="is-absolute"
            style={{
              top: 0, left: 0, zIndex: 1, width: completeWidth, height: completeHeight,
            }}
          >
            {renderedLines}
          </svg>

        </div>
      </div>
    </PanZoomWrapper>

  );
}

/*
<svg
            className="is-absolute"
            style={{
              top: 0, left: 0, zIndex: 1, width: completeWidth, height: completeHeight,
            }}
          >
            {renderedLines}
          </svg>
*/

TournamentBracketViewer.propTypes = {
  bracket: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default TournamentBracketViewer;
