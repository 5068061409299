import React, { useState, useEffect } from 'react';

// Libaries
import stats from 'analytics/matomo';

// Hooks
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useAPI from 'components/hooks/useAPI';

// Constants
import { MAX_DISPLAY_NAME_LENGTH } from 'constants/user';
import { usePlayerList } from 'components/Players/PlayerListProvider';

const regex = new RegExp(/^[\u00C0-\u017FA-Za-z0-9-_.]+$/);

function NameFilter() {
  const { t } = useTranslation(['general', 'validation']);

  const dispatch = useDispatch();

  const { post } = useAPI();

  const [name, setName] = useState('');

  const {
    filter,
    isLoading,
    setNameFilter,
  } = usePlayerList();

  useEffect(() => {
    if (filter.name === '') {
      setName('');
    }
  }, [filter.name]);

  const _setName = (_name) => {
    if (_name.length === 0) {
      setName('');
      setNameFilter('');
      return;
    }

    if (_name.length > MAX_DISPLAY_NAME_LENGTH) {
      return;
    }

    if (regex.test(_name) === false) {
      console.log('invalid name');
      return;
    }

    setName(_name);
  };

  const showSaveButton = name.length !== 0;

  return (
    <div>
      <div className="is-flex">
        <div className="is-inline-block has-max-width-250 has-text-left">
          <div className="control">
            <input
              className="input"
              type="text"
              value={name}
              placeholder={t('general:name')}
              disabled={isLoading}
              onChange={(e) => _setName(e.target.value)}
            />
          </div>
        </div>
        {
        showSaveButton && (
        <div className="has-text-left is-flex mx-2">
          <button
            type="button"
            className="button is-block is-primary grow_small has-text-weight-semibold has-margin-top-auto"
            onClick={() => setNameFilter(name)}
            disabled={isLoading}
          >
            {t('general:search')}
          </button>

        </div>
        )
      }

      </div>
    </div>

  );
}

export default NameFilter;
