import React, { useEffect, useMemo, useState } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';
import useAPI from 'components/hooks/useAPI';
import { useNavigate } from 'react-router-dom';

// Constants
import { TOURNAMENT_PAGE } from 'constants/routes';

// Components
import TriangleDisplay from 'components/utils/TriangleDisplay';
import RegionSelectorEditor from 'components/utils/RegionSelectorEditor';
import ModeSelector from 'components/utils/games/ModeSelector';
import MmrRangeSelector from 'components/utils/games/MmrRangeSelector';
import TitleEditor from './TitleEditor';

// Assets
import coverImg from 'assets/images/base/onboarding_welcome_thumbnail.jpg';
import GameSelector from 'components/utils/games/GameSelector';
import TournamentTypeSelector from './TournamentTypeSelector';

function TournamentCreator(props) {
  const { disabled, setDisabled, close } = props;

  const [title, setTitle] = useState('');
  const [tournamentType, setTournamentType] = useState('single_elimination');
  const [region, setRegion] = useState('eu');
  const [game, setGame] = useState({ tag: 'RL' });
  const [mode, setMode] = useState('');
  const [mmrRange, setMmrRange] = useState({ start: -1, end: -1 });

  const [hasMmrRangeActive, setHasMmrRangeActive] = useState(false);

  const [status, setStatus] = useState('');

  const isValid = useMemo(() => {
    if (!title || !game || !mode) return false;

    return true;
  }, [title, game, mode]);

  useEffect(() => {
    setMode('');
    setHasMmrRangeActive(false);
  }, [game]);

  useEffect(() => {
    if (hasMmrRangeActive) {
      setMmrRange({ start: 0, end: 0 });
    } else {
      setMmrRange({ start: -1, end: -1 });
    }
  }, [hasMmrRangeActive]);

  const { t } = useTranslation(['general', 'validation']);
  const { post } = useAPI();

  const navigate = useNavigate();

  const _setGame = (_game) => {
    setGame({ tag: _game });
  };

  const createTournament = async () => {
    try {
      setDisabled(true);

      const data = {
        title,
        region,
        game: {
          tag: game.tag,
          mode,
          mmrRange,
        },
        tournamentType,
      };

      const publicId = await post('/tournaments', data);

      setDisabled(false);
      close();
      navigate(`${TOURNAMENT_PAGE}/${publicId}`);
    } catch (e) {
      setStatus(t('errors:could_not_save_error'));
      setDisabled(false);
    }
  };

  return (
    <div
      style={{ height: '80vh' }}
      className="is-fullwidth has-min-width-300 has-max-width-1000 is-flex has-content-centered is-in-center has-overflow-hidden box boxshadow has-background-black-gradient p-0"
    >
      <div className="columns has-fullheight has-fullwidth is-multiline">

        <div
          className="column is-5-desktop has-min-width-300 is-5-tablet p-0"
        >
          <div
            className="has-fullheight has-text-left"
            style={{ backgroundImage: `url(${coverImg})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
          >
            <div className="p-5">
              <h1
                className="is-size-2-desktop is-size-2-tablet has-text-weight-semibold"
              >
                {t('general:create_tournament')}
              </h1>
            </div>
          </div>
        </div>

        <div className="column p-0 has-fullheight">

          <div className="field p-3 is-flex flex-direction-column has-fullheight is-relative has-background-black-gradient pb-6 has-overflow-y-auto">

            <div className="is-relative">
              <TriangleDisplay />
            </div>

            <div className="is-relative mt-6">

              <div className="columns is-multiline">
                <div className="column is-12">
                  <p className="mb-5 is-size-4">
                    {t('general:create_your_tournament')}
                  </p>
                  <TitleEditor
                    title={title}
                    setTitle={setTitle}
                    disabled={disabled}
                  />
                </div>
                <div className="column is-12">
                  <TournamentTypeSelector
                    tournamentType={tournamentType}
                    setTournamentType={setTournamentType}
                  />
                </div>
                <div className="column is-12">
                  <GameSelector
                    game={game.tag}
                    setGame={_setGame}
                    filter={['NL']}
                    showGames="full"
                  />
                  <ModeSelector
                    rank={{ mode, game: game.tag }}
                    setMode={setMode}
                  />

                  {
                    mode && (
                      <div>
                        <div>
                          <input
                            type="checkbox"
                            id="mmrRange"
                            name="mmrRange"
                            className="switch"
                            checked={hasMmrRangeActive}
                            onChange={() => setHasMmrRangeActive(!hasMmrRangeActive)}
                          />
                          <label
                            htmlFor="mmrRange"
                            className="checkbox"
                          >

                            {t('general:mmr_range')}
                          </label>
                        </div>
                        {
                          hasMmrRangeActive && (
                            <MmrRangeSelector
                              rank={{ mode, game: game.tag, mmr: mmrRange }}
                              setMmr={setMmrRange}
                            />
                          )
                        }
                      </div>
                    )
                  }
                </div>
                <div className="column is-12">
                  <RegionSelectorEditor region={region} setRegion={setRegion} />
                </div>
              </div>
            </div>

            <div className="is-inline-block has-text-centered has-margin-top-auto">
              <button
                type="button"
                onClick={createTournament}
                disabled={disabled || !isValid}
                className={`button is-size-5 px-5 has-background-primary has-text-white has-text-weight-semibold is-inline-block mt-6 ${disabled ? 'is-loading' : ''}`}
              >
                {t('general:create')}
              </button>
            </div>
            {
                status && (
                  <div className="has-text-centered mt-5">
                    <p className=" has-text-white">
                      {status}
                    </p>
                  </div>
                )
              }
          </div>
        </div>
      </div>
    </div>
  );
}

TournamentCreator.propTypes = {
  disabled: PropTypes.bool.isRequired,
  setDisabled: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};

export default TournamentCreator;
