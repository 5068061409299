import React, { useMemo, useState } from 'react';

// Hooks
import { useTranslation } from 'react-i18next';

// Libraries
import PropTypes from 'prop-types';

// Components
import ParticipantDragHandler from './ParticipantDragHandler';

// Shows the match and draws the lines to the next match, if it exists
function SeedingMatchDisplay(props) {
  const { match: matchData, childMatch: childMatchData, roundId } = props;

  const { t } = useTranslation(['general']);

  const {
    participants, result, round: roundNumber, match: matchNumber,
  } = matchData;

  // super hacky way to force a rerender when the participants change
  const _participantsToCheck = [participants[0], participants[1]];

  const participantData = useMemo(() => {
    let _participants = participants;

    if (_participants.length === 0) {
      _participants = [
        {
          cardPic: '',
          name: '',
        },
        {
          cardPic: '',
          name: '',
        },
      ];
    } else if (_participants.length === 1) {
      _participants.push({
        cardPic: '',
        name: '',
      });
    }

    return _participants;
  }, [participants, _participantsToCheck]);

  const renderedParticipants = participantData.map((participant, index) => {
    const draggableId = index === 0 ? `${roundId}_${matchNumber - 1}_0` : `${roundId}_${matchNumber - 1}_1`;

    return (
      <ParticipantDragHandler
        participant={participant}
        id={draggableId}
        isFirst={index === 0}
        key={draggableId}
      />

    );
  });

  const HEIGHT = 145;

  const calculateVerticalStartingPoint = (round, match) => {
    let roundIndex = round - 1;

    if (roundIndex < 0) roundIndex = 0;

    const heightAdjustment = round >= 1 ? 2 : 1;

    // special case for preliminary rounds
    if (round === 0) {
      if (!childMatchData) return 0;

      const { match: childMatchNumber, participants: childParticipants } = childMatchData;

      const childMatchIndex = childMatchNumber - 1;

      let preliminaryTop = (childMatchIndex * 2) * HEIGHT;

      const matchIndex = match - 1;

      // if the following match has no participants, and trhis is the second match of the round, set it below the other match
      if (matchIndex % 2 === 0 && !childParticipants[0]) {
        preliminaryTop += HEIGHT;
      }

      // if there is already one participant, set it below the other match
      if (childParticipants[0]) {
        preliminaryTop += HEIGHT;
      }

      return preliminaryTop;
    }

    return (2 ** (roundIndex) * HEIGHT * heightAdjustment) / 2 - HEIGHT / 2;
  };

  const calculateHeightIncrease = (round, match) => {
    let roundIndex = round - 1;
    const matchIndex = match - 1;

    if (roundIndex < 0) roundIndex = 0;

    if (round === 0) {
      return 0;
    }

    const heightAdjustment = round >= 1 ? 2 : 1;

    // const heightPreviousMatches = matchIndex * HEIGHT;

    return (2 ** (roundIndex) * HEIGHT * heightAdjustment) * matchIndex;
  };

  const position = useMemo(() => calculateHeightIncrease(roundNumber, matchNumber) + calculateVerticalStartingPoint(roundNumber, matchNumber), [roundNumber, matchNumber]);

  return (
    <div
      className="has-height-125 has-width-250 is-absolute is-flex flex-direction-column"
      style={{ top: `${position}px`, margin: '20px 0' }}
    >
      <div className="has-text-centered has-text-grey mb-1">
        <p className="">
          {`${t('general:match')} ${matchNumber}`}
        </p>
      </div>
      <div className="box p-0 m-0 is-flex flex-direction-column flex-grow has-overflow-hidden">
        {renderedParticipants}
      </div>
    </div>
  );
}

SeedingMatchDisplay.propTypes = {
  match: PropTypes.shape({
    participants: PropTypes.arrayOf({
      cardPic: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    result: PropTypes.arrayOf({
      score: PropTypes.number.isRequired,
    }).isRequired,
    round: PropTypes.number.isRequired,
    match: PropTypes.number.isRequired,
  }).isRequired,
  roundId: PropTypes.number.isRequired,
};

export default SeedingMatchDisplay;
