import React, {
  useEffect, useLayoutEffect, useMemo, useState,
} from 'react';

// HOCs
import { withAuthenticationRequired } from '@auth0/auth0-react';

// Hooks
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Constants
import { PLAYER_PROFILE, LOGIN } from 'constants/routes';

// Components
import Popup from 'reactjs-popup';
import PlayerCreator from './PlayerCreator';
import PlayerDetailsEditor from './PlayerDetailsEditor';
import PlayerImageEditor from './PlayerImageEditor';
import AcceptTermsHandler from './AcceptTermsHandler';

function Onboarding() {
  const { hasData: hasUserData, hasAcceptedTerms } = useSelector((state) => state.session);
  const { hasCreatedPlayer } = useSelector((state) => state.player);
  const navigate = useNavigate();

  const { t } = useTranslation();

  const [onboardingPage, setOnboardingPage] = useState(hasAcceptedTerms ? 'playerName' : 'acceptTerms');

  useLayoutEffect(() => {
    // if we dont have data, go to login
    if (!hasUserData) {
      console.log("Onboarding: doesn't have user data, navigating to login");
      navigate(LOGIN);
      return;
    }

    // if Player has already been created, go to home
    // (only check for first mount)
    // important: only run this effect if hasUserData changes, not if hasCreatedPlayer changes
    // because the first onboarding page will create a player, and want to redirect only after onboarding is done
    console.log('hasCreatedPlayer: ', hasCreatedPlayer, 'hasAcceptedTerms: ', hasAcceptedTerms);
    if (hasCreatedPlayer && hasAcceptedTerms) navigate(PLAYER_PROFILE);
  }, []);

  useEffect(() => {
    if (onboardingPage === 'done') navigate(PLAYER_PROFILE);
  }, [onboardingPage]);

  const checkIfPlayerCreationIsNeeded = () => {
    if (hasCreatedPlayer) {
      navigate(PLAYER_PROFILE);
      return;
    }

    setOnboardingPage('playerName');
  };

  const renderedOnboardingPopup = useMemo(() => {
    switch (onboardingPage) {
      case 'acceptTerms':
        return <AcceptTermsHandler onDone={checkIfPlayerCreationIsNeeded} />;
      case 'playerName':
        return <PlayerCreator onDone={() => setOnboardingPage('playerDetails')} />;
      case 'playerDetails':
        return <PlayerDetailsEditor onDone={() => setOnboardingPage('playerImage')} />;
      case 'playerImage':
        return <PlayerImageEditor onDone={() => setOnboardingPage('done')} />;
      default:
        return null;
    }
  }, [onboardingPage]);

  return (
    <div className="is-min-fullheight pb-6">
      <Popup
        position="top center"
        keepTooltipInside="#root"
        repositionOnResize
        modal
        open
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        {
            () => (
              <div className="is-fullwidth has-fullheight">
                <div className="columns is-centered has-overflow-y-auto" style={{ minHeight: '70vh' }}>
                  <div className="column has-max-width-900 is-8-desktop is-8-tablet is-12-mobile">
                    { renderedOnboardingPopup }
                  </div>
                </div>
              </div>
            )
           }
      </Popup>
    </div>
  );
}

export default withAuthenticationRequired(Onboarding);
